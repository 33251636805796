import { useCallback, useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { useLocation } from 'wouter';
import CompanyNameIconPath from '../../../assets/icons/Text field icons/Company Name Icon V2.svg';
import ShowInAppIcon from '../../../assets/Tooltip_NewSiteShowContactDetails.png';
import { ReactComponent as AddressIcon } from '../../../assets/icons/Text field icons/Address icon.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/Text field icons/Company name icon.svg';
import { ReactComponent as CountryIcon } from '../../../assets/icons/Text field icons/Country Icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { ReactComponent as MobileIcon } from '../../../assets/icons/Text field icons/Mobile icon.svg';
import { ReactComponent as NameIcon } from '../../../assets/icons/Text field icons/Name Icon V2.svg';
import { ReactComponent as TelephoneIcon } from '../../../assets/icons/Text field icons/Telephone icon.svg';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import { createSite, isSiteNameUnique, updateSite } from '../../../shared/appUIFramework/appBackend/useSites';
import {
  appSubmitAndHandleErrors,
} from '../../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import { useUnsavedPopup } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import AppTooltip from '../../../shared/appUIFramework/components/AppTooltip';
import {
  ADDRESS_MAX_LENGTH,
  EMAIL_PATTERN,
  NAME_MAX_LENGTH,
  NO_SPECIAL_CHARACTERS_PATTERN,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  PHONE_PATTERN,
  POSTCODE_MAX_LENGTH,
} from '../../../shared/appUIFramework/constants/constants';
import {
  getCountryCodeByCountryId,
  getCountryCodeForMobileCodeInForm, getCountryName,
  getMobileCodeByCountryCode, listOfCountryNamesForDropdown, listOfMobileCodesWithCountryCodeForDropdown,
  mobileCodes, useDefaultCountryCode, CountryCode, isCountryCode,
} from '../../../shared/appUIFramework/constants/Countries';
import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { ISiteInfo, ISiteInfoPartial } from '../models/ISiteInfo';
import SystemAddedPopup from './SystemAddedPopup';
import './PortalForms.scss';
import { useAppForm } from '../../../shared/appUIFramework/appBackend/useAppForm';
import { useInternalAppsCompanyDetails } from '../../../shared/appUIFramework/appBackend/useKeyContact';

let shouldConnectToSystem = false;

export function getShouldConnectToSystemAfterCreation() {
  return shouldConnectToSystem;
}

export function resetShouldConnectToSystem() {
  shouldConnectToSystem = false;
}

export default function SystemForm({ siteId = '', goBack }: { siteId?: string, goBack: () => void }) {
  const { t } = useTranslation();
  const { data: iaCompany } = useInternalAppsCompanyDetails();
  const defaultCountryCode = useDefaultCountryCode(getCountryCodeByCountryId(iaCompany?.address.countryId));
  const defaultDiallingCode = getMobileCodeByCountryCode(defaultCountryCode) || mobileCodes[0];
  const { siteInfo, mutate: mutateSite } = useSiteInfo(siteId);

  const getDefaultValue = () => ({
    siteAddress: {
      countryCode: defaultCountryCode,
    },
    siteContact: {
      diallingCode: defaultDiallingCode,
      diallingCodeCountryCode: defaultCountryCode || getCountryCodeForMobileCodeInForm(defaultDiallingCode, mobileCodes, 0),
    },
    showContactInApp: false,
  } as ISiteInfoPartial);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getFieldState,
    formState: {
      errors, isSubmitting, isDirty, isValid, isValidating,
    },
    getValues,
  } = useAppForm<ISiteInfo>(siteInfo, {
    mode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const diallingCodeCountryCode = watch('siteContact.diallingCodeCountryCode');
  const showContactDetails = watch('showContactInApp');

  const company = useCompany();
  const newSiteId = useRef<string | undefined>();
  const saveSite: (formData: ISiteInfo) => Promise<void> = async (formData: ISiteInfo) => {
    if (siteId) {
      await updateSite(formData, company?.id);
      await mutateSite!(formData);
    } else {
      const createInfo = await createSite(formData, company?.id);
      newSiteId.current = createInfo?.entityId;
    }
  };

  const [, setLocation] = useLocation();
  const goBackAfterSave = useCallback(async () => {
    if (siteId) {
      goBack();
    } else {
      shouldConnectToSystem = !!(await showPopup(<SystemAddedPopup />));
      // use set timeout, to ensure that reset is called before the location is changed in the showPopup
      setTimeout(() => setLocation(`/systems/${newSiteId.current}/${shouldConnectToSystem ? 'hardware' : 'entry-users'}`), 0);
      if (!shouldConnectToSystem) {
        goBack();
      }
    }
  }, [goBack, setLocation]);

  useUnsavedPopup('systems', isDirty);

  const changeMobileCodeDefaultIfEmpty = (countryCode: CountryCode) => {
    const mobileNumber = (getValues('siteContact.phoneNumber') ?? '').trim();
    if (!mobileNumber) {
      setValue('siteContact.diallingCodeCountryCode', countryCode);
      setValue('siteContact.diallingCode', getMobileCodeByCountryCode(countryCode));
    }
  };

  const siteInfoLoadInProgress = !!siteId && !siteInfo;
  const defaultValueLoadInProgress = !siteId && defaultCountryCode == null;
  const asyncOperationInProgress = siteInfoLoadInProgress || defaultValueLoadInProgress || isSubmitting;

  return (
    <>
      <div className="app-content">
        <AppShowLoading showLoading={asyncOperationInProgress}>
          <form
            className="app-d-flex-vertical-100 app-form"
            onSubmit={appSubmitAndHandleErrors({
              submit: saveSite, handleSubmit, reset, goBack: goBackAfterSave, mutate: mutateSite,
            })}
          >
            <div className="app-d-flex app-flex-vertical app-mobile-form app-form-container app-flex-1">
              <div className="app-form-column app-form-column-left">
                <div className="app-form-section-title system-form-title">{t('SiteAdd_SiteDetails')}</div>
                <div
                  className="app-form-control"
                  aria-invalid={!!errors.siteName && getFieldState('siteName').isTouched}
                >
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    <span>{t('SiteAdd_SiteName')}</span>
                    <span className="app-text-secondary-red-color">&nbsp;*</span>
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <CompanyIcon />
                    </div>
                    <input
                      {...register('siteName', {
                        required: true,
                        maxLength: NAME_MAX_LENGTH,
                        pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                        validate: {
                          unique: async (value: string) => {
                            if (!value || !company?.id) {
                              return true;
                            }
                            const { id } = getValues();

                            const isUnique = await isSiteNameUnique(value, company!.id, id);
                            const { siteName } = getValues();
                            if (!siteName || !siteName.trim()) {
                              return t('SiteAdd_SiteNameRequired') as string;
                            }

                            if (!isUnique) {
                              return t('SiteNameShouldBeUnique') as string;
                            }

                            return true;
                          },
                        },
                      })
                      }
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteName && getFieldState('siteName').isTouched && (
                    <span className="app-form-error app-form-error-with-prefix">
                      {errors.siteName?.type === 'required' && t('SiteAdd_SiteNameRequired')}
                      {errors.siteName?.type === 'unique' && t('SiteNameShouldBeUnique')}
                      {errors.siteName?.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                      {errors.siteName?.type === 'maxLength' && t(
                        'InputMaxLengthErrorMessage',
                        { maxLength: NAME_MAX_LENGTH },
                      )}
                    </span>
                  )}
                </div>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_Address1')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <AddressIcon />
                    </div>
                    <input
                      {...register('siteAddress.address1', {
                        maxLength: ADDRESS_MAX_LENGTH,
                      })}
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteAddress?.address1?.type === 'maxLength'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputMaxLengthErrorMessage',
                          { maxLength: ADDRESS_MAX_LENGTH },
                        )}
                      </span>
                    )}
                </div>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_Address2')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <AddressIcon />
                    </div>
                    <input
                      {...register('siteAddress.address2', {
                        maxLength: ADDRESS_MAX_LENGTH,
                      })}
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteAddress?.address2?.type === 'maxLength'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputMaxLengthErrorMessage',
                          { maxLength: ADDRESS_MAX_LENGTH },
                        )}
                      </span>
                    )}
                </div>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_City')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <AddressIcon />
                    </div>
                    <input
                      {...register('siteAddress.city', {
                        maxLength: ADDRESS_MAX_LENGTH,
                        pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      })}
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteAddress?.city?.type === 'pattern'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputInvalidCharactersErrorMessage',
                        )}
                      </span>
                    )}
                  {errors.siteAddress?.city?.type === 'maxLength'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputMaxLengthErrorMessage',
                          { maxLength: ADDRESS_MAX_LENGTH },
                        )}
                      </span>
                    )}
                </div>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_State')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <AddressIcon />
                    </div>
                    <input
                      {...register('siteAddress.state', {
                        maxLength: ADDRESS_MAX_LENGTH,
                      })}
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteAddress?.state?.type === 'maxLength'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputMaxLengthErrorMessage',
                          { maxLength: ADDRESS_MAX_LENGTH },
                        )}
                      </span>
                    )}
                </div>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_Postcode')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <AddressIcon />
                    </div>
                    <input
                      {...register('siteAddress.postCode', {
                        maxLength: POSTCODE_MAX_LENGTH,
                        pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      })}
                      type="text"
                      className="app-form-input"
                    />
                  </div>
                  {errors.siteAddress?.postCode?.type === 'pattern'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputInvalidCharactersErrorMessage',
                        )}
                      </span>
                    )}
                  {errors.siteAddress?.postCode?.type === 'maxLength'
                    && (
                      <span className="app-form-error app-form-error-with-prefix">
                        {t(
                          'InputMaxLengthErrorMessage',
                          { maxLength: POSTCODE_MAX_LENGTH },
                        )}
                      </span>
                    )}
                </div>
                <div className="app-form-control app-form-control-country">
                  <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                    {t('SiteAdd_Country')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <CountryIcon />
                    </div>
                    <Controller
                      control={control}
                      name="siteAddress.countryCode"
                      render={({ field: { onChange, value } }) => (
                        <AppSelect
                          className="app-form-input app-select-not-in-view"
                          incomingValue={getCountryName(value as CountryCode)}
                          options={listOfCountryNamesForDropdown()}
                          getOptionPrefix={(countryCode) => {
                            const country = countryCode || getValues('siteAddress.countryCode');
                            return (!!country && isCountryCode(country) && (
                              <>
                                <ReactCountryFlag
                                  countryCode={country || ''}
                                  svg
                                />
                              </>
                            ));
                          }}
                          onOptionSelected={(countryName) => {
                            onChange(countryName.value);
                            changeMobileCodeDefaultIfEmpty(countryName.value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="app-form-column app-form-separator" />
              <div className="app-d-flex app-justify-content-between app-flex-column app-form-column app-form-column-right">
                <div>
                  <div className="app-form-section-title system-form-title app-hidden">{t('SiteAdd_ContactDetails')}</div>
                  <div className="app-form-control">
                    <div className="app-form-control-input">
                      <div className="app-form-control-prefix">
                        <NameIcon />
                      </div>
                      <div className="app-d-flex app-align-items-center">
                        <Controller
                          control={control}
                          name="showContactInApp"
                          render={({ field: { onChange, value, onBlur } }) => (
                            <Switch
                              checked={!!value}
                              onChange={onChange}
                              onBlurCapture={onBlur}
                              onColor="#56aa1c"
                              offColor="#ce171d"
                              onHandleColor="#ffffff"
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={48}
                              className="lkk-switch app-pr-12"
                              id="material-switch"
                            />
                          )}
                        />
                        <div className="app-weight-600 app-font-20 app-color-primary-black app-mr-98">
                          {t('Form_ContactInApp')}
                        </div>
                        <AppTooltip>
                          <div className="app-font-20 app-color-primary-green">
                            {t('Form_ShowContactInApp')}
                          </div>
                          <div className="app-d-flex form-wide-tooltip">
                            <div className="app-flex-1">
                              <div className="app-font-16-22 app-w-170 app-mt-43">
                                {t('Form_EnablingShowContactInApp')}
                              </div>
                            </div>
                            <div>
                              <img
                                className="app-w-100pcnt"
                                src={ShowInAppIcon}
                                alt="sample"
                              />
                            </div>
                          </div>
                        </AppTooltip>
                      </div>
                    </div>
                  </div>
                  {showContactDetails
                    && (
                      <>
                        <div className="app-form-control">
                          <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                            {t('Form_CompanyName')}
                          </div>
                          <div className="app-form-control-input">
                            <div className="app-form-control-prefix">
                              <img src={CompanyNameIconPath} alt="company name icon" />
                            </div>
                            <input
                              {...register('siteContact.details', {
                                maxLength: NAME_MAX_LENGTH,
                              })}
                              type="text"
                              className="app-form-input"
                            />
                          </div>
                          {errors.siteContact?.details?.type === 'maxLength'
                            && (
                              <span className="app-form-error app-form-error-with-prefix">
                                {t(
                                  'InputMaxLengthErrorMessage',
                                  { maxLength: ADDRESS_MAX_LENGTH },
                                )}
                              </span>
                            )}
                        </div>
                        <div className="app-form-control">
                          <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                            {t('Form_PhoneNumber')}
                          </div>
                          <div
                            aria-invalid={!!errors.siteContact?.phoneNumber2}
                            className="app-form-control-input"
                          >
                            <div className="app-form-control-prefix">
                              <TelephoneIcon />
                            </div>
                            <input
                              {...register(
                                'siteContact.phoneNumber2',
                                { pattern: PHONE_PATTERN, minLength: PHONE_MIN_LENGTH, maxLength: PHONE_MAX_LENGTH },
                              )}
                              type="text"
                              className="app-form-input"
                            />
                          </div>
                          {errors.siteContact?.phoneNumber2 && (
                            <div className="app-form-error app-form-error-with-prefix">
                              {errors.siteContact.phoneNumber2.type === 'pattern' && t('Form_PhoneLengthInvalid')}
                              {errors.siteContact.phoneNumber2.type !== 'pattern' && t('Form_PhoneNumberInvalid')}
                            </div>
                          )}
                        </div>
                        <div className="app-form-control app-form-control-mobile-number">
                          <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                            {t('Form_MobileNumber')}
                          </div>
                          <div className="app-form-control-input">
                            <div className="app-form-control-prefix">
                              <MobileIcon />
                            </div>
                            <Controller
                              control={control}
                              name="siteContact.diallingCode"
                              render={({ field: { onChange, value } }) => (
                                <AppSelect
                                  className="app-select-dialling-code"
                                  incomingValue={value}
                                  disableSearch
                                  isOptionSelected={(countryCode) => !!diallingCodeCountryCode && countryCode.value === diallingCodeCountryCode}
                                  readOnly
                                  onOptionSelected={(countryCode) => {
                                    onChange(countryCode.label);
                                    setValue('siteContact.diallingCodeCountryCode', countryCode.value);
                                  }}
                                  getOptionPrefix={(countryCode) => {
                                    const country = countryCode || getValues('siteContact.diallingCodeCountryCode') || defaultCountryCode;
                                    return (
                                      <>
                                        {!!country && (
                                          <ReactCountryFlag
                                            countryCode={country || ''}
                                            svg
                                          />
                                        )}
                                      </>
                                    );
                                  }}
                                  options={listOfMobileCodesWithCountryCodeForDropdown()}
                                />
                              )}
                            />
                            <div
                              className="app-form-control-input-wrapper"
                              aria-invalid={!!errors.siteContact?.phoneNumber}
                            >
                              <input
                                {...register(
                                  'siteContact.phoneNumber',
                                  { pattern: PHONE_PATTERN, minLength: PHONE_MIN_LENGTH, maxLength: PHONE_MAX_LENGTH },
                                )}
                                autoComplete="off"
                                type="text"
                                className="app-form-input"
                              />
                            </div>
                          </div>
                          {errors.siteContact?.phoneNumber && (
                            <div className="app-form-error app-form-error-with-prefix">
                              {errors.siteContact.phoneNumber.type === 'pattern' && t('Form_PhoneLengthInvalid')}
                              {errors.siteContact.phoneNumber.type !== 'pattern' && t('Form_PhoneNumberInvalid')}
                            </div>
                          )}
                        </div>
                        <div
                          className="app-form-control"
                          aria-invalid={!!errors.siteContact?.email}
                        >
                          <div className="app-form-control-label app-form-control-label-with-prefix app-form-label">
                            {t('SiteAdd_EmailAddress')}
                          </div>
                          <div className="app-form-control-input">
                            <div className="app-form-control-prefix">
                              <EmailIcon />
                            </div>
                            <input
                              {...register('siteContact.email', { pattern: EMAIL_PATTERN })}
                              type="text"
                              className="app-form-input"
                            />
                          </div>
                          {errors.siteContact?.email && (
                            <div className="app-form-error app-form-error-with-prefix">
                              {t('SiteAdd_EmailInvalid')}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                </div>
                <div className="app-d-flex app-justify-content-end app-form-bottom-action-bar">
                  <button
                    onClick={goBack}
                    className="app-secondary-button app-mr-9"
                    type="button"
                  >
                    {t('Form_Cancel')}
                  </button>
                  <button
                    disabled={!isValid || isSubmitting || isValidating}
                    className="app-primary-button"
                    type="submit"
                  >
                    {t('Form_Save')}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </AppShowLoading>
      </div>
    </>
  );
}
