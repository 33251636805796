import {
  ReactNode, RefObject, useEffect, useRef,
} from 'react';

export default function AppOutsideListener({
  onClick,
  inputRef,
  children,
  exceptionRefs,
}:{
    onClick: (e: Event) => unknown,
    inputRef?: RefObject<HTMLDivElement>,
    children?: ReactNode,
    exceptionRefs?: Array<RefObject<HTMLDivElement | SVGSVGElement> | undefined | null>,
  }) {
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const ref = inputRef || componentRef;
    const handleClickOutSide = (e: Event) => {
      const clickOnElementThatNotChild = ref.current
        && !ref.current.contains(e.target as Node);

      let clickOnException = false;
      if (exceptionRefs) {
        exceptionRefs.forEach((exceptionRef) => {
          if (exceptionRef && exceptionRef.current && exceptionRef.current.contains(e.target as Node)) {
            clickOnException = true;
          }
        });
      }

      if (clickOnElementThatNotChild && !clickOnException) {
        onClick(e);
      }
    };
    window.addEventListener('click', handleClickOutSide);

    return () => window.removeEventListener('click', handleClickOutSide);
  }, [onClick]);
  return inputRef ? <>{children}</> : (
    <div ref={componentRef}>
      {children}
    </div>
  );
}
