import {
  useEffect, useState,
} from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';
import { ReactComponent as AddressIcon } from '../../../assets/icons/Text field icons/Address icon.svg';
import { ReactComponent as IDIcon } from '../../../assets/icons/Text field icons/EntryUser_Icon_123.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { ReactComponent as NameIcon } from '../../../assets/icons/Text field icons/Name Icon V2.svg';
import DisplayNameIcon from '../../../assets/icons/Text field icons/Display name v2.svg';
import { useAddressBook } from '../../../shared/appUIFramework/appBackend/useAddressBook';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import { useEntryUser } from '../../../shared/appUIFramework/appBackend/useEntryUser';
import { isEmailUnique, resendUserEmail } from '../../../shared/appUIFramework/appBackend/useEntryUsers';
import {
  appSubmitAndHandleErrors,
} from '../../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import { useUnsavedPopup } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import AppTooltip, { AppTooltipPlacement } from '../../../shared/appUIFramework/components/AppTooltip';
import {
  CALL_NAME_MAX_LENGTH,
  EMAIL_PATTERN,
  GROUP_MAX_LENGTH,
  MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID,
  MONITOR_ID_MAX_VALUE,
  MONITOR_ID_MIN_VALUE,
  NAME_MAX_LENGTH,
  NO_SPECIAL_CHARACTERS_PATTERN,
  PHONE_PATTERN,
} from '../../../shared/appUIFramework/constants/constants';
import { RegistrationStatus } from '../../../shared/appUIFramework/constants/RegistrationStatus';
import { IUserData } from '../models/IUserData';
import { useSiteInfo } from '../../../shared/appUIFramework/appBackend/useSiteInfo';
import styles from '../../payment/PaymentPayments.module.scss';
import { useAppForm } from '../../../shared/appUIFramework/appBackend/useAppForm';
import { getEntryUserFormSubmitHandler, getEntryUserReinstateHandler } from './entryUserForm.methods';
import EntryUserFormRegistrationStatus from './EntryUserFormRegistrationStatus';
import { IHardwareDevice } from '../models/IHardware';
import {
  getAmountOfUsersWithSameIdInTheGroup,
  getCallName,
  getEntryUserDeviceGroups,
  isAnyDeviceWithSameNameAndGroupButOtherId,
  isDisplayNameEditable,
  tryPopulateCallNameFromDeviceWithSameIdAndGroup,
} from './entryUserForm.monitors';
import { ISiteInfo } from '../models/ISiteInfo';
import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import NewUserEmailConfirmationPopup from '../../../shared/appUIFramework/appForm/NewUserEmailConfirmationPopup';
import AppSystemErrorPopup from '../../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';
import { useDebounce } from '../../../shared/appUIFramework/hooks/useDebounce';
import { UserIdTooltipBody } from './EntryUserFieldTooltipComponents';

function useEntryUserForm(entryUser?: IUserData, devices?: IHardwareDevice[], siteInfo?: ISiteInfo):
  UseFormReturn<IUserData> & {
    isValid: boolean,
    setDisplayName: (displayName: string) => void,
  } {
  const initialData = entryUser && {
    ...entryUser,
    siteInfo: {
      ...entryUser.siteInfo,
      callName: entryUser.siteInfo.callName ?? '',
    },
  };

  const form = useAppForm<IUserData>(initialData, {
    mode: 'onChange',
    defaultValues: {
      isActive: true,
      registrationStatus: RegistrationStatus.Unknown,
    },
  });

  // https://github.com/react-hook-form/react-hook-form/issues/2755
  const isValid = Object.keys(form.formState.errors).length === 0 && form.formState.isDirty;

  useEffect(() => {
    form.trigger();
  }, [devices, siteInfo, isValid]);

  return {
    ...form,
    isValid,
    setDisplayName: (displayName: string) => {
      form.setValue('siteInfo.callName', displayName);
    },
  };
}

function useGoToEntryUsersPage(siteId: string | undefined) {
  const [shouldGoToEntryUsers, setShouldGoToEntryUsers] = useState(false);
  const goToEntryUsers = () => setShouldGoToEntryUsers(true);
  const [, setLocation] = useLocation();
  useEffect(() => {
    if (shouldGoToEntryUsers) {
      setLocation(`/systems/${siteId}/entry-users`);
    }
  }, [shouldGoToEntryUsers]);
  return goToEntryUsers;
}

export default function EntryUserForm({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation();
  const [, params] = useRoute('/systems/:siteId/entry-users/:entryUserId');
  const siteId = params?.siteId;
  const entryUserId = params?.entryUserId === '0' ? '' : params?.entryUserId;
  const goToEntryUsersPage = useGoToEntryUsersPage(siteId);
  const isNew = !entryUserId;
  const { entryUser, mutate } = useEntryUser(siteId, entryUserId);
  const { siteInfo } = useSiteInfo(siteId);
  if (!siteId) {
    throw new Error('Site Id is not defined');
  }
  const { devices, source } = useAddressBook(siteInfo);
  const deviceGroups = getEntryUserDeviceGroups(devices);
  const company = useCompany();

  const {
    register,
    trigger,
    getFieldState,
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    isValid,
    setDisplayName,
    formState: {
      errors, isSubmitting, isDirty,
    },
    setValue,
  } = useEntryUserForm(entryUser, devices, siteInfo);

  const formCallId = watch('siteInfo.callId');
  const formGroupId = watch('siteInfo.groupId');
  const formRegistrationStatus = watch('registrationStatus');
  const formIsActive = watch('isActive');

  const submitEntryUserForm = getEntryUserFormSubmitHandler({
    isNew,
    companyId: company?.id,
    siteId,
    devices,
    refreshExistingUser: mutate,
    addressBookSource: source,
  });

  useUnsavedPopup('entryUsers', isDirty);

  const entryUserLoadInProgress = !!entryUserId && !entryUser;
  const siteInfoLoadInProgress = !siteInfo;
  const isDisplayNameDisabled = !isDisplayNameEditable(devices || [], !!siteInfo?.isSiteBound, formCallId, formGroupId);

  useEffect(() => {
    const { siteInfo: { callId, groupId, callName } } = getValues();
    setValue('siteInfo.callName', getCallName(devices || [], callId, groupId, callName));
  }, [devices]);

  const setCallIdChangedDebounce = useDebounce(() => {
    setDisplayName('');
    tryPopulateCallNameFromDeviceWithSameIdAndGroup(devices || [], getValues('siteInfo.callId'), getValues('siteInfo.groupId'), setDisplayName);
    trigger();
  }, 500);

  return (
    <div className="app-content">
      <AppShowLoading showLoading={entryUserLoadInProgress || isSubmitting || siteInfoLoadInProgress}>
        <form
          className="app-form app-d-flex-vertical-100"
          onSubmit={appSubmitAndHandleErrors({
            submit: submitEntryUserForm, handleSubmit, reset, goBack: goToEntryUsersPage, mutate,
          })}
        >
          <div
            className="app-form-section-title"
            aria-disabled={disabled}
          >
            {t('AddUser_Details')}
          </div>
          <div
            className="app-d-flex app-flex-vertical app-mobile-form app-form-container app-flex-1"
            aria-disabled={disabled}
          >
            <div className="app-form-column app-form-column-left">
              <div
                className="app-form-control"
                aria-invalid={!!errors.firstName && getFieldState('firstName').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  <span>{t('SiteAdd_FirstName')}</span>
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('firstName', {
                      required: true,
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      disabled,
                    })}
                    type="text"
                    className="app-form-input"
                  />
                </div>
                {errors.firstName && getFieldState('firstName').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.firstName?.type === 'required' && t('Form_FieldRequired')}
                    {errors.firstName?.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.firstName?.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.lastName && getFieldState('lastName').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_LastName')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('lastName', {
                      required: true,
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      disabled,
                    })}
                    type="text"
                    className="app-form-input"
                  />
                </div>
                {
                  errors.lastName && getFieldState('lastName').isTouched && (
                    <div className="app-form-error app-form-error-with-prefix">
                      {errors.lastName?.type === 'required' && t('Form_FieldRequired')}
                      {errors.lastName?.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                      {errors.lastName?.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                    </div>
                  )
                }
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.email && getFieldState('email').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_EmailAddress')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <EmailIcon />
                  </div>
                  <input
                    {...register('email', {
                      pattern: EMAIL_PATTERN,
                      required: true,
                      disabled,
                      validate: {
                        unique: async (value: string) => {
                          if (!value || !company?.id) {
                            return true;
                          }

                          if (!value || !value.trim()) {
                            return t('Form_FieldRequired') as string;
                          }

                          const isUnique = await isEmailUnique(value, siteId!, entryUserId);
                          if (!isUnique) {
                            return t('EmailShouldBeUniquePerSite') as string;
                          }

                          return true;
                        },
                      },
                    })}
                    type="text"
                    className="app-form-input"
                    disabled={!isNew}
                  />
                </div>
                {errors.email && getFieldState('email').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.email.type === 'pattern' && t('SiteAdd_EmailInvalid')}
                    {errors.email.type === 'required' && t('Form_FieldRequired')}
                    {errors.email.type === 'unique' && t('EmailShouldBeUniquePerSite')}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors?.siteInfo?.groupId && getFieldState('siteInfo.groupId').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('User_GroupName')}
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <AddressIcon />
                  </div>
                  <Controller
                    control={control}
                    name="siteInfo.groupId"
                    rules={{
                      maxLength: GROUP_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                    }}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <AppSelect
                        className="app-form-select app-border-none"
                        disabled={disabled}
                        placeholder={t('SelectGroup')}
                        onBlur={() => {
                          onBlur();
                        }}
                        treatInputValueAsOption
                        incomingValue={value}
                        options={deviceGroups.map((deviceGroup) => ({ value: deviceGroup, label: deviceGroup })) || []}
                        onOptionSelected={(option) => {
                          tryPopulateCallNameFromDeviceWithSameIdAndGroup(devices || [], getValues('siteInfo.callId'), option.value, setDisplayName);
                          onChange(option.value);
                        }}
                      />
                    )}
                  />
                  <AppTooltip
                    preferredPlacement={AppTooltipPlacement.Right}
                    className={`app-post-input-content app-ml-28 ${styles.tooltip}`}
                  >
                    <UserIdTooltipBody />
                  </AppTooltip>
                </div>
                {errors?.siteInfo?.groupId && getFieldState('siteInfo.groupId').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.siteInfo.groupId.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.siteInfo.groupId.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: GROUP_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.siteInfo?.callId && getFieldState('siteInfo.callId').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('User_CallId')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <IDIcon />
                  </div>
                  <input
                    {...register('siteInfo.callId', {
                      onChange: async () => {
                        setCallIdChangedDebounce();
                      },
                      disabled,
                      min: MONITOR_ID_MIN_VALUE,
                      max: MONITOR_ID_MAX_VALUE,
                      pattern: PHONE_PATTERN,
                      required: true,
                      validate: {
                        maximumUsersWithSameCallId: (callId: string) => {
                          if (!callId || !callId.trim() || !company?.id) {
                            return true;
                          }

                          const maximumAllowedDevicesInArray = isNew
                            ? MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID
                            : MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID + 1;
                          if (getAmountOfUsersWithSameIdInTheGroup(devices || [], callId, getValues('siteInfo.groupId')) >= maximumAllowedDevicesInArray) {
                            return t('MaximumUsersWithSameCallId', { maximumUsersWithSameCallId: MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID }) as string;
                          }

                          return true;
                        },
                      },
                    })}
                    type="text"
                    className="app-form-input"
                  />
                  <div className="app-post-input-content">
                    <AppTooltip preferredPlacement={AppTooltipPlacement.Right} className={`app-ml-28 ${styles.tooltip}`}>
                      <div
                        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
                      >
                        {t('Table_ID')}
                      </div>
                      <div className="app-mt-28">
                        {t('Tooltip_ID')}
                      </div>
                      <div className="app-mt-28 app-mb-17">
                        {t('TheIdBeMustBeBetween1and9998')}
                      </div>
                    </AppTooltip>
                  </div>
                </div>
                {errors.siteInfo?.callId && getFieldState('siteInfo.callId').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {(errors.siteInfo.callId.type === 'pattern'
                      || errors.siteInfo.callId.type === 'max'
                      || errors.siteInfo.callId.type === 'min') && t('IdMustBeBetween1And9998')}
                    {errors.siteInfo.callId.type === 'required' && t('Form_FieldRequired')}
                    {errors.siteInfo.callId.type === 'maximumUsersWithSameCallId'
                      && t('MaximumUsersWithSameCallId', { maximumUsersWithSameCallId: MAXIMUM_MOBILE_USERS_WITH_SAME_CALL_ID })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.siteInfo?.callName && getFieldState('siteInfo.callName').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('User_CallName')}
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <img
                      src={DisplayNameIcon}
                      alt="logo display name"
                    />
                  </div>
                  <input
                    {...register('siteInfo.callName', {
                      maxLength: CALL_NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      disabled: disabled || isDisplayNameDisabled,
                      validate: {
                        nameShouldUnique: (name) => {
                          const isNameEmpty = name?.trim() === '';
                          return isNameEmpty ? true : !isAnyDeviceWithSameNameAndGroupButOtherId(devices || [], formCallId, name, getValues('siteInfo.groupId'), isNew);
                        },
                      },
                    })}
                    disabled={disabled || isDisplayNameDisabled}
                    type="text"
                    className="app-form-input"
                  />
                  <div className="app-post-input-content">
                    <AppTooltip preferredPlacement={AppTooltipPlacement.Right} className={`app-ml-28 ${styles.tooltip}`}>
                      <div
                        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
                      >
                        {t('User_CallName')}
                      </div>
                      <div className="app-mt-28 app-mb-17">
                        {isDisplayNameDisabled ? t('Tooltip_NewMobileUserDisplayName_InUse') : t('Tooltip_NewMobileUserDisplayName')}
                      </div>
                    </AppTooltip>
                  </div>
                </div>
                {errors.siteInfo?.callName && getFieldState('siteInfo.callName').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.siteInfo?.callName.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: CALL_NAME_MAX_LENGTH })}
                    {errors.siteInfo?.callName.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.siteInfo?.callName.type === 'nameShouldUnique' && t('Form_CallNameNotUnique')}
                  </div>
                )}
              </div>
            </div>
            <div className="app-d-flex app-justify-content-between app-flex-column app-form-column app-form-column-right">
              <EntryUserFormRegistrationStatus
                disabled={disabled}
                registrationStatus={formRegistrationStatus}
                control={control}
                isActive={formIsActive}
                isNewEntryUser={isNew}
                reinstate={getEntryUserReinstateHandler({
                  resetFormValues: reset,
                  updateEntryUserOnUi: mutate!,
                  siteId: siteId!,
                  getOriginalValues: getValues,
                  goBackOnError: goToEntryUsersPage,
                })}
                resendConfirmationLink={async () => {
                  try {
                    const userData = getValues();
                    const success = await resendUserEmail(userData.id, siteId);
                    if (success) {
                      await showPopup(<NewUserEmailConfirmationPopup email={userData.email} />);
                    } else {
                      await showPopup(<AppSystemErrorPopup />);
                    }
                  } catch (e) {
                    await showPopup(<AppSystemErrorPopup />);
                  }
                }}
              />
              <div className="app-d-flex app-justify-content-end app-form-bottom-action-bar">
                <button
                  onClick={goToEntryUsersPage}
                  className="app-secondary-button app-mr-9"
                  type="button"
                >
                  {t('Form_Cancel')}
                </button>
                <button
                  disabled={!isValid || isSubmitting}
                  className="app-primary-button"
                  type="submit"
                >
                  {t('Form_Save')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </AppShowLoading>
    </div>
  );
}
